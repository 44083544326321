.attendanceCalendarForm {
    display: flex;
    flex-direction: column;
    max-width: 300px; 
    width: 100%; 
    padding: 20px;
    background-color: #fff; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    max-height: 600px; 
    overflow-y: auto;
    justify-content: center;
  }