/* .fc {
    max-width: 900px;
    margin: 40px auto;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
} */
/*
.fc-header-toolbar {
    /* Calendar header 
    background-color: #f7f7f7;
    padding: 10px;
    border-radius: 4px 4px 0 0;
}

.fc button {
    /* Calendar buttons 
    background-color: #007bff;
    color: white;
    border: none;
    padding: 5px 10px;
    margin: 2px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.fc button:hover {
    background-color: #0056b3;
} */




/* Style for the modal */
.modal {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
  }
  .modalOverlay {
    position: fixed;
    max-width: 450px; 
    height: 100vh;
    width: 100%; 
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Ensure the overlay covers other elements */
  }
  
  /* Style for the form inside the modal */
  .calendarForm {
    display: flex;
    flex-direction: column;
    max-width: 300px; 
    width: 100%; 
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    max-height: 600px; 
    overflow-y: auto;
    justify-content: center;
  }
  /* form {
    display: flex;
    flex-direction: column;
    max-width: 300px; 
    width: 100%; 
    padding: 20px;
    background-color: #fff; 
    border-radius: 8px; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); 
    max-height: 600px; 
    overflow-y: auto;
    justify-content: center;
  } */
  
  /* Style for form labels */
  label {
    margin-bottom: 2px; 
  }
  
  /* Style for form inputs */
  input,
  textarea {
    width: 100%;
    padding: 8px; 
    margin-bottom: 16px; 
    box-sizing: border-box; 
  }
  
  /* Style for buttons */
  button {
    background-color: #007bff; 
    color: #fff; 
    padding: 10px; 
    border: none; 
    border-radius: 4px; 
    cursor: pointer; 
  }
  
  button[type="button"] {
    background-color: #ccc; 
    color: #333; 
    margin-right: 8px; 
  }
  
  /* Hover effect for buttons */
  button:hover {
    background-color: #0056b3; 
  }  

 
  

  
  
