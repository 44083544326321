.reset-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 98.7vh;
  }
  
  .reset-password-container .logo {
    width: 150px;
    margin-bottom: 10px;
  }
  
  .reset-password-container .arrow-icon {
    width: 30px;
    height: 30px;
    position: relative;
    right: 180px;
    top: 35px;
  }
  
  .reset-password-container .arrow-icon:hover {
    transform: scale(1.03);
    background-color: rgb(238, 238, 238);
    box-shadow: 5px 3px 12px 5px rgb(230, 230, 230);
    transition: 0.5s ease;
    cursor: pointer;
  }
  
  .password-form-container {
    width: 300px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    height: 350px;
    position: relative;
    top: -20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .reset-password-form {
    display: flex;
    flex-direction: column;
  }
  
  .reset-password-form label {
    margin-bottom: 5px;
  }
  
  .reset-password-form input {
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    transition: border-color 0.3s ease;
  }
  
  .reset-password-form input:focus {
    border-color: #007bff;
  }
  