.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  width: 150px; /* Adjust the width as per your logo size */
  margin-bottom: 20px;
}

.login-form-container {
  width: 300px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-form label {
  margin-bottom: 5px;
}

.login-form input {
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  transition: border-color 0.3s ease;
}

.login-form input:focus {
  border-color: #007bff;
}

.login-form .submit {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form .submit:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
}
.forgot-button {
  background: none;
  margin: 8px 0px;
  color: black;
  position: relative;
  left: 65px;
  padding: 0px;
}
.forgot-button:hover {
  background: none;
  transform: scale(1.02);
}
/* Ensure the password icon is placed inside the input box */
.password-input-container {
  position: relative;
  display: flex;
  align-items: center;
}

.password-input-container input {
  flex-grow: 1;
  padding-right: 30px; /* Add padding to avoid overlap with the icon */
}

.password-toggle-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
  color: #333;
}

.password-toggle-icon:hover {
  color: #007bff; /* Optional: change color on hover */
}
