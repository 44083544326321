  .fc-toolbar {
    background-color: #f8f9fa;
    padding: 10px;
    border-bottom: 1px solid #dee2e6;
  }
  
  .fc-toolbar-title {
    color: #343a40;
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .fc-daygrid-day {
    border: 1px solid #dee2e6;
    background-color: #ffffff;
    text-decoration: none;
    position: relative;
  }
  .fc-daygrid-day-number{
    text-decoration: none;
    color: black;
    font-size: 20px;
  }
  .fc-col-header-cell-cushion{
    text-decoration: none;
    color: black;
  }

  .fc-daygrid-more-link{
    text-decoration: none;
    justify-content: center;
    position: absolute;
    top: 25px;
    left: 30%;
    font-size: 20px;
  }
  .fc-daygrid-day:hover {
    background-color: #f1f3f5;
  }
  /* Style for events */
  .fc-event {
    margin: 10px;
    display: block;
    color: blue;
    border: none;
    border-radius: 4px;
    padding: 10px;
    text-align: center;
  }
  
  .fc-event:hover {
    background-color: #0056b3;
    color: white;
    transition: 0.5s ease-in;
  }
  
  /* Event title and description */
  /* .fc-event-title {
    font-weight: bold;
  }
  
  .fc-event-description {
    font-style: italic;
    color: #6c757d;
  } */
  .fc-event-resizable{
    height: 40px;
    color: black;
  }