.react-calendar-heatmap rect.color-empty {
    fill: #d6e2e9; /* Grey color */
  }

  .color-filled {
    fill: green; /* Set the fill color to green */
  }

  .react-calendar-heatmap .react-calendar-heatmap-month-label {
    font-size: 14px; /* Decrease the font size of the month label */
  }

  .react-calendar-heatmap .react-calendar-heatmap-weekday-label {
    font-size: 10px; /* Decrease the font size of the weekday labels */
  }

  .react-calendar-heatmap .react-calendar-heatmap-cell {
    width: 10px; /* Decrease the width of each calendar cell */
    height: 10px; /* Decrease the height of each calendar cell */
  }

  